import { FunctionComponent } from 'react';
import { Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';
import Link from 'next/link';
import NextLink from 'next/link';

import { Button, Checkbox, Text, Textinput } from '@/atoms';

import { TypeSuscriptionFormProps } from './types';
import { ButtonVariant } from '@/atoms/button/types';
import toast, { Toaster } from 'react-hot-toast';
import { gtmPush } from '@/utils';
import { Tick } from '@/icons';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MoonLoader } from "react-spinners";


export const SuscriptionForm: FunctionComponent<TypeSuscriptionFormProps> = ({
    title,
    subtitle,
    backgroundColor,
    textButton,
    nameLabel,
    lastNameLabel,
    emailLabel,
    defaultEmail,
    evento,
    className = '',
}: TypeSuscriptionFormProps) => {
    const FIELD_TOO_SHORT = 'Demasiado corto!';
    const FIELD_TOO_LONG = 'Demasiado largo';

    const RegisterSchema = object().shape({
        name: string()
            .required('El nombre es obligatorio')
            .min(2, FIELD_TOO_SHORT)
            .max(50, FIELD_TOO_LONG),
        surname: string()
            .min(2, FIELD_TOO_SHORT)
            .max(50, FIELD_TOO_LONG),
        email: string()
            .required('El email es obligatorio')
            .min(2, FIELD_TOO_SHORT)
            .max(50, FIELD_TOO_LONG),
        accept_privacy: boolean()
            .oneOf([true], 'Debes aceptar las políticas de privacidad'),
        accept_commercial: boolean(),
    });
    const initialValues = {
        name: '',
        surname: '',
        email: defaultEmail,
        accept_privacy: false,
        accept_commercial: false,
    };

    const { executeRecaptcha } = useGoogleReCaptcha();

    return (
        <div style={{ backgroundColor: backgroundColor }} className={className}>
            <div className="flex flex-col">
                <Text className="text-subtitle mb-4">{title}</Text>
                {subtitle && (
                    <Text className="text-body text-body--m mb-2">{subtitle}</Text>
                )}
                <Formik
                    onSubmit={async ({
                        name,
                        surname,
                        email,
                        accept_privacy,
                        accept_commercial,
                    }, { resetForm }) => {
                        const json = {
                            webform_id: 'newsletter',
                            gReCaptchaToken: await executeRecaptcha("enquiryFormSubmit"),
                            email: email,
                            name: name,
                            surname: surname,
                            accept_privacy: accept_privacy,
                            accept_commercial: accept_commercial,
                        };

                        const response = await fetch('/api/submitform', {
                            method: "POST",
                            body: JSON.stringify(json),
                        });
                        if (response.ok) {
                            gtmPush({
                                event: 'suscripcion_newsletter',
                            });
                            gtmPush({
                                event: 'join_group',
                                group_id: 'lead',
                            });
                            toast('Recibirá un email con los pasos a seguir.', {
                                icon: (
                                    <Tick
                                        width={64}
                                        color="#fff"
                                        className="p-3 rounded-full bg-status-success font-bold"
                                    />
                                ),
                                duration: 3000,
                                style: {
                                    background: '#575757',
                                    padding: '16px',
                                    fontWeight: 'bold',
                                    marginBottom: '80px',
                                    color: '#fff',
                                },
                            });
                            setTimeout(() => evento(false), 4500);
                        }
                        resetForm({ values: initialValues })
                    }}
                    validationSchema={RegisterSchema}
                    initialValues={initialValues}
                >
                    {({
                        values, isSubmitting
                    }) => (
                        <Form>
                            <div className="flex flex-col justify-between">
                                <div className="mb-4 w-full">
                                    <Textinput label={nameLabel} name="name" />
                                </div>
                                <div className="mb-1 w-full">
                                    <Textinput
                                        label={lastNameLabel}
                                        name="surname"
                                        className="mb-1 w-full"
                                    />
                                </div>
                                <div className="mb-1 w-full">
                                    <Textinput
                                        label={emailLabel}
                                        name="email"
                                        type="email"
                                        className="mb-1 w-full"
                                        placeholder="ejemplo@gmail.com"
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <Checkbox name="accept_privacy" checked={values.accept_privacy}>
                                    <p>
                                        He leído y acepto la{' '}
                                        <NextLink
                                            href="/es/politica-privacidad"
                                            prefetch={false}
                                            passHref
                                            legacyBehavior>
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="!leading-5 text-primary-500 hover:text-primary-600 my-4"
                                                href="/es/politica-privacidad">
                                                política de privacidad.
                                            </Link>
                                        </NextLink>
                                    </p>
                                </Checkbox>
                                <Checkbox name="accept_commercial" checked={values.accept_commercial}>
                                    <p>Acepto recibir información comercial.</p>
                                </Checkbox>
                            </div>
                            <p className="text-body text-xs md:text-body--s my-4 text-justify">
                                Campofrío Food Group, S.A.U (CFG) utilizará los datos que proporcione en este formulario con la finalidad de atender la consulta planteada por este medio. La base de legitimación para el tratamiento de sus datos por parte de CFG se encuentra en la relación contractual entablada entre el usuario y CFG. Puede ejercitar sus derechos de protección de datos dirigiéndose por escrito al domicilio social en Avenida de Europa 24, 28108, Alcobendas, Madrid o a través del correo electrónico&nbsp;<a className="text-primary-500 hover:text-primary-600 break-words" href="mailto:dpo.external@campofriofg.com">dpo.external@campofriofg.com</a>. Más información: consultando nuestra <Link href="/es/politica-privacidad" className="text-primary-500 hover:text-primary-600 break-words">Política de Privacidad</Link>.
                            </p>
                            <p className="text-body text-xs md:text-body--s my-4">
                                Este sitio esta protegido por reCAPTCHA y se aplican la <Link className="text-primary-500 hover:text-primary-600" target="_blank" href="https://policies.google.com/privacy" rel="noreferrer">Politica de Privacidad</Link> y los <Link className="text-primary-500 hover:text-primary-600" href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Términos del Servicio</Link> de Google.
                            </p>

                            {isSubmitting ?
                                <div className="flex justify-center">
                                    <MoonLoader color="#c78938" loading={true} speedMultiplier={0.6} className="spinner" />
                                </div>
                                :
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    type="submit"
                                    disabled={!values.accept_privacy}
                                >
                                    {textButton}
                                </Button>
                            }
                            <Toaster
                                position="bottom-center"
                                containerStyle={{ position: 'absolute' }}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
};

SuscriptionForm.displayName = 'SuscriptionForm';

import { FunctionComponent } from 'react';
import { Field } from 'formik';
import { oneLine } from 'common-tags';

import { TypeCheckBoxProps } from './types';

/**
 * Checkbox
 */
export const Checkbox: FunctionComponent<TypeCheckBoxProps> = ({
  children,
  disabled = false,
  name,
  className = '',
  value,
  checked,
  ...props
}: TypeCheckBoxProps) => (
  <label
    htmlFor={`${name}-${value}`}
    className={oneLine`
      inline-flex gap-3 cursor-pointer text-link text-sm md:text-link--m select-none
      ${disabled ? 'opacity-40' : ''}
      ${className}
    `}
  >
    <Field
      type="checkbox"
      id={`${name}-${value}`}
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      className="w-5 h-5 cursor-pointer border-secondary-600 checked:text-primary-500 checked:ring-0 focus:ring-0 focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
      {...props}
    />
    {children}
  </label>
);
